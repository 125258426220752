<template>
    <div class="col-12">
        <template v-if="commercegateDataError || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="commercegateDataError" class="alert alert-danger mt-5 text-center">{{commercegateDataError}} {{$t('request-unsuccessful')}}</p>
        </template>

        <div v-else-if="commercegateData">
            <!--Success-->
            <form :action="commercegateData.apiUrl" method="GET" ref="commercegateForm">
                <input type="hidden" name="cid" :value="commercegateData.customerId">
                <input type="hidden" name="wid" :value="commercegateData.websiteId">
                <input type="hidden" name="token" :value="commercegateData.token">
                <input type="redirect" name="redirect" :value="commercegateData.redirect">
            </form>
        </div>
    </div>
</template>

<script>
    import {getCommercegateData} from "../../../services/backend.service";
    import {getProviderUrl} from "../../../services/backend.service";
    import {validationMixin} from 'vuelidate';
    import {required, numeric, minValue, maxValue, email} from 'vuelidate/lib/validators';


    export default {
        // This page is accessed with a transaction ID. A call is made with that transaction ID to get Commercegate deposit data.
        // With that data we fill a hidden form on the page and submit it automatically.
        name: 'Commercegate',
        data(){
            return{
                notEnoughData: null,
                commercegateDataError: null,
                commercegateData: null,
            }
        },
        components:{
            Error: () => import('../../others/Error'),
        },
        updated(){
            if(this.commercegateData){
                this.submitForm();
            }
        },
        mounted() {
            debugger;

            this.language = this.$router.history.current.query.language;
            this.customerId = this.$router.history.current.query.customerId;
            this.ipAddress = this.$router.history.current.query.ipAddress;
            this.playerCurrency = this.$router.history.current.query.playerCurrency;
            this.playerCurrencySymbol = this.$router.history.current.query.playerCurrencySymbol;
            this.amount = this.$router.history.current.query.amount;

            if(!this.customerId || !this.ipAddress || !this.playerCurrency || !this.amount || !this.playerCurrencySymbol){
                this.notEnoughData = true;
            } else {
                this.redirectToCommercegate();
            }
        },
        methods: {
            submitForm() {
                this.$refs.commercegateForm.submit();
            },

            getCommercegate(transactionId) {
                if (transactionId) {
                    getCommercegateData(transactionId).then(res => {
                        if (res.data.success && res.data.data) {
                            this.commercegateData = res.data.data;
                        } else {
                            this.commercegateDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                        }
                    }).catch(e => {
                        this.commercegateDataError = this.$t('error-2');
                        console.log(`%c Error in CommercegateDeposit.vue - method: getCommercegateData - ${e}`, 'color:red');
                    });
                } else {
                    this.notEnoughData = true;
                }
            },

            redirectToCommercegate(fromSubmit) {
                getProviderUrl({
                    currency: this.playerCurrency,
                    amount: this.amount,
                    processorName: "COMMERCEGATE",
                    processorType: "EWALLET",
                    customerId: this.customerId,
                    ipAddress: this.ipAddress,
                    language: this.language
                }).then(res => {
                    this.showLoader = false;
                    this.submitStatus = null;
                    if (res.data && res.data.success && res.data.data && res.data.data.redirectUrl) {
                        this.getCommercegate(res.data.data.redirectUrl)
                    } else if (res.data && res.data.success && res.data.data && res.data.data.minLimitAmount !== null && res.data.data.maxLimitAmount !== null && res.data.data.errorCode && res.data.data.errorCode !== 'account-invalid') {
                        this.minDeposit = (res.data.data.minLimitAmount / 100).toFixed(2);
                        this.maxDeposit = (res.data.data.maxLimitAmount / 100).toFixed(2);
                        this.amount = Math.round(res.data.data.maxLimitAmount / 100);
                        this.limitReached = this.$t(res.data.data.errorCode);
                    } else if (res.data && res.data.success && res.data.data && res.data.data.errorCode && res.data.data.errorCode === 'account-invalid') {
                        this.accountInvalid = this.$t(res.data.data.errorCode);
                    } else {
                        this.netellerDepositError = this.$t('error-2');
                    }
                }).catch(e => {
                    this.showLoader = false;
                    this.netellerDepositError = this.$t('error-2');
                    console.log(`%c Error in NetellerDeposit.vue - method: redirectToNeteller - ${e}`, 'color:red');
                });
            }
        }
    }
</script>

<style scoped></style>
